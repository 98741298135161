import React from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import Helpers from './Helpers'
import {Endpoint} from "./api"

export class singleHadith extends React.Component {

    state = {
        isLoading: true,
        hadiths: [],
        error: null
      };
      fetchHadiths() {
        const { match: { params } } = this.props;

        const type_id = Helpers.getTypeId(params.book_name);

        fetch( Endpoint + `type/${type_id}/hadith/${params.hadith_number}`)
          .then(response => response.json())
          .then(data =>
            this.setState({
              hadiths: data,
              isLoading: false,
            })
          )
          .catch(error => this.setState({ error, isLoading: false }));
      }
    
      componentDidMount() {
        this.fetchHadiths();
      }

    render(){
        //console.log(this.props)
        const { isLoading, hadiths, error } = this.state;
        const { match: { params } } = this.props;
        return(
            <React.Fragment>

            {error ? <p>{error.message}</p> : null}
            {!isLoading ? (          
              hadiths.map(hadith => {
                const { id, book_number, hadith_number, narrated_en, hadith_text_en, hadith_text_ar } = hadith;
                return (
                  <div className="col-12 p-3 mb-5 single-hadith" key={id}>
                    
                    <Helmet>
                      <title>Hadith Bukhari - Book:{book_number} - Hadith:{hadith_number}</title>
                      <meta name="description" content={`Hadith Bukhari - Book:${book_number} - Hadith:${hadith_number} ${narrated_en}`} />
                    </Helmet> 

                    <p className="arabic narrated-by text-green">{Helpers.englishToArabic(hadith_number)}</p>

                    <p className="arabic">{hadith_text_ar}</p>

                    <p className="narrated-by text-gray">{narrated_en}</p>

                    <p className="english text-body">{hadith_text_en}</p>
                    
                    <p><span className="text-body">Reference: </span> <small className="text-secondary"><Link to={`/books/${params.book_name}/hadith/${hadith_number}`}>Book {book_number}, Hadith {hadith_number}</Link></small></p>

                  </div>
                );
              })
            ) : (
              <h3>Loading...</h3>
            )}
          </React.Fragment>
        )
    }

}