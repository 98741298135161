import React from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import Helpers from './Helpers'
import {Endpoint} from "./api"

export class Chapter extends React.Component {

    state = {
        isLoading: true,
        hadiths: [],
        hadiths_meta: [],
        error: null
      };

    
      fetchHadiths() {
        const { match: { params } } = this.props;

        const type_id = Helpers.getTypeId(params.book_name);

        fetch( Endpoint + `type/${type_id}/chapter/${params.book_number}`)
          .then(response => response.json())
          .then(data =>
            this.setState({
              hadiths_meta: data.meta,
              hadiths: data.data,
              isLoading: false,
            })
          )
          .catch(error => this.setState({ error, isLoading: false }));
      }
    
      componentDidMount() {
        this.fetchHadiths();
      }

    render(){
        //console.log(this.props)
        const { isLoading, hadiths, hadiths_meta, error } = this.state;
        const { match: { params } } = this.props;
        
        return(
            <React.Fragment>
              {hadiths_meta.map(hadiths_meta => {
                const { id, book_number, book_range, book_type, name_arabic, name_english } = hadiths_meta;
                return (                
                  <div className="col-12 mb-5 p-3 box-shadow" key={id}>
                    <Helmet>
                      <title>Hadith Bukhari - {name_english} - ({book_range}) - ${name_arabic}</title>
                      <meta name="description" content={`Hadith Bukhari - ${name_english} - ${name_arabic}`} />
                    </Helmet>
                    <div className="row">
                      <div className="col-7">
                      <span className="book-number">{book_number}</span>
                      <Link to={`/books/${params.book_name}`}><span className="english">{name_english} ({book_range})</span></Link>
                      </div>
                      <div className="col-5"><span className="arabic d-block"><span className="text-green">{Helpers.englishToArabic(book_number)}</span> {name_arabic}</span></div>
                    </div>
                  </div>
                );
              })}
            {error ? <p>{error.message}</p> : null}
            {!isLoading ? (          
              hadiths.map(hadith => {
                const { id, book_number, hadith_number, narrated_en, hadith_text_en, hadith_text_ar } = hadith;
                return (
                  <div className="col-12 mb-5 p-3 single-hadith" key={id}>
                    
                    <p className="arabic narrated-by text-green">{Helpers.englishToArabic(hadith_number)}</p>

                    <p className="arabic mb-5">{hadith_text_ar}</p>

                    <p className="narrated-by text-gray">{narrated_en}</p>

                    <p className="english text-body">{hadith_text_en}</p>
                    
                    <div className="reference">
                    <span className="text-body small">Reference: </span> 
                    <span className="small"><Link to={`/books/${params.book_name}/hadith/${hadith_number}`}>Book {book_number}, Hadith {hadith_number}</Link>
                    </span>
                    </div>

                  </div>
                );
              })
            ) : (
              <h3>Loading...</h3>
            )}
          </React.Fragment>
        )
    }

}