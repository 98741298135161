import React from 'react';
import { Link } from 'react-router-dom'
import {Endpoint} from "./api"

export class Bukhari extends React.Component {

    state = {
        isLoading: true,
        chapters: [],
        error: null,
      };
    
      fetchChapters() {
        
        fetch( Endpoint + 'type/2')
          .then(response => response.json())
          .then(data =>
            this.setState({
              chapters: data,
              isLoading: false,
            })
          )
          .catch(error => this.setState({ error, isLoading: false }));
      }
    
      componentDidMount() {
        this.fetchChapters();
      }

    render(){

        //console.log(Endpoint);
      
        const { isLoading, chapters, error } = this.state;

        const book_name = 'bukhari';

        return(
            <React.Fragment>

            <h1 className="col-12 mb-5">Sahih al-Bukhari</h1>
            
            {error ? <p>{error.message}</p> : null}
            {!isLoading ? (          
              chapters.map(hadith => {
                const { id, name_english, name_arabic, book_range, book_number } = hadith;
                return (

                  <div className="chapter col-12 pb-3 pt-3" key={id}>

                  
                    <div className="row">
                      <div className="count col-1">{book_number}</div>
                      <div className="english-book-name col-5"><Link to={`/books/${book_name}/chapter/${book_number}`}>{name_english}</Link></div> 
                      <div className="arabic col-4">{name_arabic}</div> 
                      <div className="book-range text-right col-2">{book_range}</div> 
                    </div>
                  
                  
                  </div>
                );

              })
            ) : (
              <h3>Loading...</h3>
            )}
          </React.Fragment>
        )
    }

}