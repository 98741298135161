import React, { Component } from 'react';

//import logo from './logo.svg';
import './App.css';


import {Root} from "./components/Root";
import Main from "./components/Main";



class App extends Component {

  render() {
    return (
      <Root>
        <Main />
      </Root>
    );
  }
}

export default App;