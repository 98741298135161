const Helpers = {

    getTypeId: function(book){
        var id = null;
        switch(book) {
          case 'bukhari':
            id = 2;
            break;
          default:
            // code block
        }       

        return id;

      },
      
      englishToArabic: function(english){
        var id= ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
        return english.replace(/[0-9]/g, function(w){
        return id[+w]
        });
      }

}

export default Helpers;