import React from 'react';
import { Link } from 'react-router-dom'

export class Root extends React.Component {

    render(){
        return(
            <div className="container">
                <div className="row">
                <header className="mb-5">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item"><Link to='/books/bukhari' className="nav-link">Sahih al-Bukhari</Link></li>
                        </ul>
                        </nav>
                </header>
                </div>
                <div className="row">

                        {this.props.children}
                </div>
            </div>
        )
    }

}