import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import {Bukhari} from "./Bukhari"
import {Chapter} from "./Chapter"
import {singleHadith} from "./singleHadith"



// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route exact path='/books/bukhari/' component={Bukhari}/>
      <Route exact path='/books/:book_name/chapter/:book_number' component={Chapter}/>
      <Route exact path='/books/:book_name/hadith/:hadith_number' component={singleHadith}/>
    </Switch>
  </main>
)

export default Main
